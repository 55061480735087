import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

const Header = (props) => {
  const [videoActive, setVideoActive] = useState(true);
  let colors = useSelector((state) => state.AdminReducer.colors);
  const { data, translate, folder_name, lang, modules } = useSelector((state) => state.AdminReducer);

  const videoRemoval = () => {
    setVideoActive(false);
  };
  const styleFullscreen = {
    height: "100%",
    width: "100%",
    minWidth: "100%",
    minHeight: "100vw",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 3,
  };

  var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',];
  var d = new Date();
  var dayName = days[d.getDay() - 1];

  const options = {
    day: "numeric",
    month: "long",
  };

  return (
    <header
      style={{
        height:
          props.height === "full"
            ? "100vh"
            : props.height === "half"
              ? "75vh"
              : "100vh",
      }}
      id="home"
    >
      {/* <div className="background" style={{backgroundImage: props.image ? "url(" + props.image + ")" : "none",}}> */}
      <div className="background" style={{ backgroundImage: `url(https://o-sn.be/kml/test-onepages/${folder_name}/background.jpg)` }}>
        <div
          className="signature"
          style={{
            zIndex:
              props.logo_and_slogan_visibility === true
                ? 5
                : props.logo_and_slogan_visibility === "hidden"
                  ? 0
                  : 5,
            opacity: !props.logo_and_slogan_visibility ? 0 : 1,
          }}
        >
          <img
            className="logo"
            src={`${props.logo}`}
            alt={"Logo " + props.name}
            style={{
              display: props.logo_visibility === true ? "block" : "none",
            }}
          />
          <h1 className={props.slogan_template ? "temp-" + props.slogan_template : "temp-1"} style={{ display: !props.slogan && "none" }}>
            <span>{props.slogan ? props.slogan : props.name}</span>
          </h1>
        </div>
        {data && data.openinghours && data.openinghours.length > 0 &&
          <section className={`openinghours ${colors.oh_color ? 'oh_colors' : ''} ${!modules.filter((e) => e.name === 'Home')[0].display_oh ? 'inactive' : ''} ${modules.filter((e) => e.name === 'Home')[0].align}`}>
            <div className="openinghourscontent section">
              <div className="header">
                <h2>{translate('openinghours')}</h2>

              </div>
              <table className="opening-hours-table">
                <tbody>
                  {Object.entries(data.openinghours[0].week).map(([key, value], index) => (
                    value.hours.length > 0 ?
                      value.hours.map((res, idx) => (
                        <tr key={key + idx} className={`${key === dayName ? 'active' : ''} ${data.openinghours[0].exception_days.includes(key) ? 'holliday' : ''}`}>
                          {/* {idx === 0 ? <td><p>{translate(key)} {new Date(d.getFullYear(), d.getMonth(), d.getDate() + index - 1).toLocaleDateString("nl-NL", options).replace('-', '/')} </p></td> : <td><p></p></td>} */}
                          {idx === 0 ? <td><p>{translate(key)[0].toUpperCase() + translate(key).substring(1)} {new Date(d.getFullYear(), d.getMonth(), d.getDate() + (days.indexOf(key) - d.getDay() + 1)).toLocaleDateString(lang === 'nl' ? "nl-NL" : "fr-FR", options).replace('-', '/')} </p></td> : <td><p></p></td>}
                          {value.is_closed === true ?
                            <td className="opens" style={{ fontZize: "14px" }}>{translate('closed')}</td>
                            :
                            <td className="opens" style={{ fontZize: "14px" }}>{res.from.slice(0, 5)} - {res.to.slice(0, 5)}</td>
                          }
                        </tr>
                      ))
                      :
                      <tr key={key} className={`${key === dayName ? 'active' : ''} ${data.openinghours[0].exception_days.includes(key) ? 'holliday' : ''}`}>
                        <td><p>{translate(key)[0].toUpperCase() + translate(key).substring(1)} {new Date(d.getFullYear(), d.getMonth(), d.getDate() + (days.indexOf(key) - d.getDay() + 1)).toLocaleDateString(lang === 'nl' ? "nl-NL" : "fr-FR", options).replace('-', '/')}</p></td>
                        {value.is_closed === true ?
                          <td className="opens" style={{ fontZize: "14px" }}>{translate('closed')}</td>
                          :
                          <td className="opens" style={{ fontZize: "14px" }}></td>
                        }
                      </tr>
                  ))}
                </tbody></table>
            </div>
          </section>}
        <div className="backgroundOverlay" style={{ background: props.theme === "dark" ? "var(--grey-90)" : props.theme === "main" ? colors.main : props.theme === "sub" ? colors.sub : props.theme === "gradient" ? `linear-gradient(45deg, ${colors.sub} 0%, ${colors.main} 100%)` : "var(--grey-90)", opacity: props.overlay_opacity ? props.overlay_opacity : "0.1", zIndex: !videoActive || props.type === "image" ? -1 : 4, }}></div>
        {props.type === "video" || props.type === "both"
          ? props.video &&
          videoActive && (
            <ReactPlayer
              url={props.video}
              playing={true}
              muted={true}
              controls={false}
              width={"100%"}
              height={"100%"}
              loop={props.type === "video" ? true : false}
              onEnded={videoRemoval.bind(this)}
              style={
                props.fullscreen
                  ? styleFullscreen
                  : { position: "relative", zIndex: 3 }
              }
            />
          )
          : null}
      </div>
    </header>
  );
};

export default Header;
